@import "base/mixins/reduced-motion.scss";
@import "base/mixins/reset.scss";
@import "mixins/font-size.scss";

/* main styles */
html,
body {
    text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    font-family: $font-default;
    font-size: vw-vh-contain($base-px-desktop);
    font-weight: $font-weight-default;
    color: $color-text;
    height: auto;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;

    @include font-size;
    @include reduced-motion;
}

html {
    background: linear-gradient(187deg, #8055dd 0%, #464bab 16.67%, #3f2088 33.33%, #3f2087 48.46%, #254d9b 62.88%, #a05bf6 80.5%, #3d50cd 92.67%, #39298b 100%), #6c52cb;
}

main,
footer {
    backdrop-filter: blur(150px);
}

@include reset;

// Remove if total buttons reset is not needed
a,
button {
    @include reset-btn;
}

a {
    text-decoration: underline;
    cursor: pointer;
    transition: color ease-in-out 0.2s;

    &:hover {
        color: #18ffff;
        transition: color ease-in-out 0.2s;
    }
}

b,
strong {
    /* Customizing font-weight according to design */

    /* e.g. Semi Bold */
    font-weight: $font-weight-accent;
}

.container {
    width: 100%;
    padding-left: $padding-x-content-desktop;
    padding-right: $padding-x-content-desktop;
    margin-left: auto;
    margin-right: auto;

    @media (--viewport-mobile-pt) {
        padding-left: $padding-x-content-mobile;
        padding-right: $padding-x-content-mobile;
    }
}

em {
    color: $color-text-accent;
    font-style: normal;
}

.backlight-text {
    text-shadow: rem(25) rem(4) rem(30) $color-text-accent;
    font-weight: 600;

    @media (--viewport-mobile) {
        text-shadow: none;
        font-weight: $font-weight-default;
    }
}

.backlight-numbers {
    font-family: $font-accent;
    text-shadow: 0 4px 30px #18ffff, 1px 1px 10px rgb(24 255 255 / 50%);
    font-weight: 600;
}

.entered {
    transition: transform 0.6s ease-out, opacity 0.8s ease-out;
    opacity: 1;
    transform: translate(0, 0);
}
