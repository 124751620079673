$fs: $base-px-desktop;
$fs-mb: $base-px-mb;

.brush-lines {
    font-size: vw($fs);
    width: em(1446.89, $fs);
    height: em(957.59, $fs);
    position: absolute;

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
        width: em(964.17, $fs-mb);
        height: em(638.11, $fs-mb);
    }

    &__anim,
    &__element {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__element {
        opacity: 0.2;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("@/assets/images/icons/header/brush-lines.png");
    }
}
