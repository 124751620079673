@font-face {
    font-family: Involve;
    src:
        url("assets/fonts/Involve-Bold.woff2") format("woff2"),
        url("assets/fonts/Involve-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Involve;
    src:
        url("assets/fonts/Involve-SemiBold.woff2") format("woff2"),
        url("assets/fonts/Involve-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Involve;
    src:
        url("assets/fonts/Involve-Medium.woff2") format("woff2"),
        url("assets/fonts/Involve-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Involve;
    src:
        url("assets/fonts/Involve-Regular.woff2") format("woff2"),
        url("assets/fonts/Involve-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Caviar Dreams";
    src:
        url("assets/fonts/CaviarDreams-Bold.woff2") format("woff2"),
        url("assets/fonts/CaviarDreams-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Caviar Dreams";
    src:
        url("assets/fonts/CaviarDreams.woff2") format("woff2"),
        url("assets/fonts/CaviarDreams.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
