.header {
    $fs: $base-px-desktop;
    $fs-mb: $base-px-mb;

    font-size: vw($fs);
    width: 100vw;
    height: 100vh;
    position: relative;

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
    }

    &__decor {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .decor {
            &,
            &__anim,
            &__element {
                position: absolute;
            }

            &__anim,
            &__element {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &--shadow {
                bottom: 0;
                left: 0;
                width: 100%;
                height: em(329.28, $fs);
                background: linear-gradient(360deg, #3e1e84 8.49%, rgb(60 32 133 / 0%) 100%);
                z-index: 3;
            }

            &--gradient {
                width: 100%;
                height: 100%;
                background: linear-gradient(69.15deg, #410776 5.92%, #3557a6 51.53%, #af57ff 88.85%);

                @media (--viewport-mobile-pt) {
                    background: linear-gradient(14.33deg, #410776 12.1%, #3557a6 55.66%, #af57ff 91.3%);
                }
            }
        }
    }

    .brush-lines {
        left: em(148, $fs);
        bottom: em(-308, $fs);
        z-index: 1;

        @media (--viewport-mobile-pt) {
            bottom: em(-202, $fs-mb);
            left: em(180.22, $fs-mb);
        }

        &__element {
            transform: rotate(-8.4deg);
        }
    }

    .stars-mixin.header__stars {
        font-size: vw($fs);
        right: em(-266, $fs);
        top: em(109, $fs);
        z-index: 1;

        @media (--viewport-mobile-pt) {
            font-size: vh($fs-mb, true);
            right: em(-258.85, $fs-mb);
            top: em(341.14, $fs-mb);
        }

        .stars-mixin__transform {
            transform: rotate(15.2deg);
        }
    }

    &__title {
        position: relative;
        max-width: em(630, $fs);
        width: 100%;
        padding-top: em(106.91, $fs);
        z-index: 2;

        @media (--viewport-mobile-pt) {
            padding-top: em(80, $fs-mb);
            text-align: center;
        }
    }

    &__h1 {
        font-size: em(50, $fs);
        line-height: em(65, 50);
        font-weight: $font-weight-default;

        @media (--viewport-mobile-pt) {
            font-size: em(25, $fs-mb);
            line-height: 130%;
            padding-left: em(38, 25);
            padding-right: em(38, 25);
        }

        &:not(.entered) {
            opacity: 0;
            transform: translateX(em(-20, 50));
        }
    }

    &__subtitle {
        font-size: em(74, $fs);
        line-height: em(90, 74);
        font-family: $font-accent;
        font-weight: $font-weight-bold;
        color: $color-text-accent;
        text-transform: uppercase;
        margin-top: em(30, 74);

        @media (--viewport-mobile-pt) {
            font-size: vw(37, true);
            line-height: 142.86%;
            margin-top: em(5, 35);
        }

        &:not(.entered) {
            transition-duration: 0.2s;
            opacity: 0;
            transform: translateX(em(-10, 74));
        }
    }
}
