$w: 80;
$wMb: 40;

.share {
    font-size: vw-vh-contain($w);
    display: flex;
    gap: em(8, $w);
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    @media (--viewport-tablet-pt), (--viewport-mobile-pt) {
        font-size: vw-vh-contain($wMb, true);
    }

    &__item {
        width: em($w, $w);
        height: em($w, $w);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: background 0.1s ease-in;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
