.description {
    $fs: $base-px-desktop;
    $fs-mb: $base-px-mb;

    position: relative;

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(68deg, #3f1f85 0%, #3f1f85 66.02%);

        @media (--viewport-mobile-pt) {
            background: linear-gradient(180deg, #3f1f85 0%, #3e2e96 100%);
        }
    }

    &__wrap {
        display: flex;
        padding-top: em(82, $fs);
        padding-bottom: em(158, $fs);

        @media (--viewport-mobile-pt) {
            padding-top: em(31.09, $fs-mb);
            padding-bottom: em(31.7, $fs-mb);
        }
    }

    .rectangle-background {
        width: percent(723, 1160);
        height: em(421, $fs);
        background: rgb(85 46 169 / 63%);
        box-shadow: 0 em(3.12, $fs) em(62.5, $fs) rgb(44 17 102 / 44%);
        backdrop-filter: blur(5.86px);
        border-radius: em(100, $fs);
        padding: em(64, $fs) em(64, $fs) em(95, $fs) em(85, $fs);

        @media (--viewport-mobile-pt) {
            width: 100%;
            border-radius: em(60, $fs-mb);
            height: auto;
            padding: em(49.5, $fs-mb) em(15, $fs-mb) em(49.5, $fs-mb) em(20, $fs-mb);
        }
    }

    &__title {
        font-size: em(25, $fs);
        font-weight: $font-weight-accent;
        line-height: em(32, 25);
        margin-bottom: em(35, 25);

        &:not(.entered) {
            opacity: 0;
            transform: translateY(em(-10, 25));
        }

        @media (--viewport-mobile-pt) {
            font-size: em($fs-mb, $fs-mb);
            width: 100%;
            border-radius: em(60, $fs-mb);
            font-weight: 600;
        }
    }

    &__text {
        &:not(.entered) {
            transition-duration: 0.2s;
            opacity: 0;
            transform: translateY(em(-10, $fs));
        }
    }

    &__img {
        width: percent(437, 1160);
        position: relative;

        @media (--viewport-mobile-pt) {
            display: none;
        }
    }

    &__icon {
        position: absolute;
        width: percent(489, 437);
        height: em(304, $fs);
        bottom: em(-82, $fs);
        right: em(-52, $fs);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("@/assets/images/icons/description.png");
        z-index: 2;

        &:not(.entered) {
            transition-delay: 1.1s;
            opacity: 0;
            transform: translateX(em(-30, $fs));
        }
    }

    &__stars.stars-mixin {
        font-size: 1em;
        width: 100vw;
        height: em(606, $fs);
        left: 0;
        bottom: 0;

        @media (--viewport-mobile-pt) {
            display: none;
        }
    }

    .brush-lines {
        font-size: vw($fs);
        left: em(-386, $fs);
        top: em(130, $fs);
        transform: rotate(43deg);

        @media (--viewport-mobile-pt) {
            display: none;
        }
    }
}
