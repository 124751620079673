// @import from elements
@import "description.scss";

.main {
    $fs: $base-px-desktop;
    $fs-mb: $base-px-mb;
    $fs-mb-lp: 28;

    width: 100%;
    font-size: vw($fs);
    overflow: hidden;

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
    }

    .block-1 {
        padding-top: em(106, $fs);
        padding-bottom: em(106, $fs);

        @media (--viewport-mobile-pt) {
            padding-top: em(32.23, $fs-mb);
            padding-bottom: em(32.23, $fs-mb);
            background: #3f2e96;
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: em(70, $fs);

                @media (--viewport-mobile-pt) {
                    margin-bottom: em(25, $fs-mb);
                }
            }
        }

        &__title {
            font-size: em(25, $fs);
            line-height: em(32.5, 25);
            font-weight: $font-weight-accent;
            margin-bottom: em(40, 25);

            @media (--viewport-mobile-pt) {
                font-size: em($fs-mb, $fs-mb);
                font-weight: 600;
                margin-bottom: em(25, $fs-mb);
            }

            &:not(.entered) {
                transition-duration: 0.4s;
                opacity: 0;
                transform: translateY(em(-20, 25));
            }
        }

        &__text {
            &:not(.entered) {
                transition-duration: 0.6s;
                opacity: 0;
                transform: translateY(em(-10, $fs));
            }
        }
    }

    .block-2 {
        position: relative;
        padding-top: em(80, $fs);
        padding-bottom: em(80, $fs);
        overflow: hidden;

        @media (--viewport-mobile-pt) {
            padding-top: em(31, $fs-mb);
            padding-bottom: em(67, $fs-mb);
            background: linear-gradient(180deg, #3e2e96 0%, #4541a8 28%, #4d58be 100%);
        }

        &__wrap {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__stars.stars-mixin {
            font-size: 1em;
            width: 100vw;
            height: em(532, $fs);
            left: 0;
            bottom: 0;

            @media (--viewport-mobile-pt) {
                font-size: vh($fs-mb);
                width: em(1120.46, $fs-mb);
                height: em(431.36, $fs-mb);
                left: em(-243, $fs-mb);
                bottom: auto;
                top: em(-120, $fs-mb);
            }
        }

        .brush-lines {
            display: none;

            @media (--viewport-mobile-pt) {
                display: block;
                font-size: vh($fs-mb);
                width: em(1119.42, $fs-mb);
                height: em(740.86, $fs-mb);
                left: em(-532, $fs-mb);
                top: em(52, $fs-mb);
                transform: rotate(43deg);
            }
        }

        .around-background {
            position: absolute;
            width: 100%;
            height: 100%;
            fill: #d9d9d9;
            background-size: cover;
            background-position: left;
            background-repeat: round;
            background-image: url("@/assets/images/icons/around-background.png");

            @media (--viewport-mobile-pt) {
                background-size: inherit;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("@/assets/images/icons/around-background-mb.png");
                border-radius: em(60, $fs-mb);
            }

            &__blur {
                position: absolute;
                width: 100%;
                height: 100%;
                box-shadow: 0 3.12px 62.5px rgb(44 17 102 / 18%);
                backdrop-filter: blur(5.86px);
                border-radius: em(130, $fs) em(55, $fs) em(130, $fs) em(55, $fs);

                @media (--viewport-mobile-pt) {
                    border-radius: em(60, $fs-mb);
                }
            }
        }

        &__list {
            position: relative;
            padding: em(70, $fs) em(100, $fs);

            @media (--viewport-mobile-pt) {
                padding: em(50, $fs) em(25, $fs-mb) em(50, $fs) em(32, $fs-mb);
            }
        }

        &__title,
        &__text {
            font-weight: $font-weight-default;
        }

        &__title {
            color: $color-text-accent;
            font-size: em(70, $fs);
            font-style: normal;
            line-height: 130%;

            &:not(.entered) {
                transition-duration: 0.4s;
                opacity: 0;
                transform: translateY(em(-20, 70));
            }

            span {
                font-size: em(50, 70);
            }
        }

        &__text {
            font-size: em(18, $fs);

            &:not(.entered) {
                transition-duration: 0.6s;
                opacity: 0;
                transform: translateY(em(-10, 18));
            }
        }

        &__item {
            &:first-child {
                margin-bottom: em(25, 18);
            }

            &:nth-child(3) {
                margin-bottom: em(35, 18);
            }

            &:nth-child(5) {
                margin-bottom: em(45, 18);
            }
        }
    }

    .block-3 {
        padding-top: em(96, $fs);
        padding-bottom: em(75, $fs);

        @media (--viewport-mobile-pt) {
            padding-top: em(28, $fs-mb);
            padding-bottom: 0;
            background: linear-gradient(180deg, #4d58be 0%, #9057eb 44.5%, #392a8b 100%);
        }

        &__title {
            font-size: em(40, $fs);
            font-weight: $font-weight-default;
            line-height: 130%;
            margin-bottom: em(70, 40);
            color: rgb(255 255 255 / 99%);
            text-align: center;

            &:not(.entered) {
                transition-duration: 0.4s;
                opacity: 0;
                transform: translateY(em(-30, 40));
            }

            @media (--viewport-mobile-pt) {
                font-size: em(23, $fs-mb);
                margin-bottom: em(27, 23);
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-gap: em(31, $fs) em(36, $fs);

            @media (--viewport-mobile-pt) {
                display: flex;
                flex-direction: column;
            }

            &.entered {
                .block-3 {
                    &__item {
                        @for $i from 1 to 6 {
                            &:nth-of-type(#{$i}) {
                                transition: opacity 0.5s ease-in-out;
                                transition-duration: #{$i * 0.514}s;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &:not(.entered) {
                .block-3 {
                    &__item {
                        transition: opacity 0.5s ease-in-out;
                        opacity: 0;
                    }
                }
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            row-gap: em(15, $fs);
            padding: em(37, $fs) em(31, $fs) em(47, $fs);
            border-radius: em(60, $fs);
            background: linear-gradient(180deg, rgb(6 192 242 / 21%) 0%, rgb(6 192 242 / 2.1%) 104.94%);
            mix-blend-mode: normal;
            box-shadow: 0 3.125px 62.51px 0 rgb(44 17 102 / 18%);
            backdrop-filter: blur(5.86px);

            @media (--viewport-mobile-pt) {
                padding: em(30, $fs-mb) em(13.81, $fs-mb);
                border-radius: em(60, $fs);
            }

            &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;

                @media (--viewport-mobile-pt) {
                    order: 1;
                }
            }

            &:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;

                @media (--viewport-mobile-pt) {
                    order: 2;
                }
            }

            &:nth-child(3) {
                grid-area: 1 / 3 / 2 / 4;

                @media (--viewport-mobile-pt) {
                    order: 3;
                }
            }

            &:nth-child(4) {
                grid-area: 2 / 1 / 3 / 3;
                padding-left: em(60.38, $fs);
                padding-right: em(60.38, $fs);

                @media (--viewport-mobile-pt) {
                    padding: em(30, $fs-mb) em(13.81, $fs-mb);
                    order: 5;
                }
            }

            &:nth-child(5) {
                grid-area: 2 / 3 / 3 / 4;
                padding-left: em(19.86, $fs);
                padding-right: em(19.86, $fs);

                @media (--viewport-mobile-pt) {
                    order: 4;
                }
            }
        }

        &__icon {
            height: em(78, $fs);

            &--SupportIcon {
                width: em(99.17, $fs);
            }

            &--SecurityIcon {
                width: em(62.44, $fs);
            }

            &--IndependentIcon {
                width: em(94.82, $fs);
            }

            &--ConfidentialityIcon {
                width: em(71.81, $fs);
                height: em(69, $fs);
            }

            &--LegalityIcon {
                width: em(61.8, $fs);
                height: em(69, $fs);
            }
        }

        &__subtitle {
            font-size: em(25, $fs);
            line-height: 130%;
            text-align: center;
            margin-bottom: em(13, 25);

            @media (--viewport-mobile-pt) {
                font-size: em(16, $fs-mb);
                font-weight: 600;
            }
        }

        &__text {
            font-size: em(16, $fs);
            line-height: 130%;
            text-align: center;

            @media (--viewport-mobile-pt) {
                font-size: em(14, $fs-mb);
            }
        }
    }
}
