.stars-mixin {
    $fs: $base-px-desktop;
    $fs-mb: $base-px-mb;
    $anim-duration: 998.9s;
    $anim-timing-function: cubic-bezier(0, -0.52, 0.02, 1);

    font-size: vw($fs);
    position: absolute;
    width: em(1114.77, $fs);
    height: em(379.53, $fs);
    transform: scaleY(0.91);
    animation: #{$anim-duration * 0.1} $anim-timing-function -0.2s infinite alternate scaling-y-mixin;

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
        width: em(742.85, $fs-mb);
        height: em(252.91, $fs-mb);
    }

    &__transform,
    &__anim,
    &__element {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__anim {
        transform: scaleX(0.87);
        animation: #{$anim-duration * 0.1} $anim-timing-function -0.8s infinite alternate scaling-x-mixin;
    }

    &__element {
        transform: rotate(0deg);
        animation: $anim-duration $anim-timing-function -0.8s infinite alternate rotate-el-mixin;
    }

    .star-mixin {
        opacity: 0;

        @for $i from 1 to 66 {
            &--#{$i} {
                $p: ($i * 0.5) * 0.0092;

                animation: #{($i * 0.5) * 1.0092}s $anim-timing-function #{$p}s infinite flickering-mixin;
            }
        }
    }

    @keyframes scaling-y-mixin {
        0% {
            transform: scaleY(0.91);
        }

        50% {
            transform: scaleY(1);
        }

        100% {
            transform: scaleY(0.91);
        }
    }

    @keyframes scaling-x-mixin {
        0% {
            transform: scaleX(0.87);
        }

        50% {
            transform: scaleX(1);
        }

        100% {
            transform: scaleX(0.87);
        }
    }

    @keyframes rotate-el-mixin {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(10deg);
        }

        100% {
            transform: rotate(0);
        }
    }

    @keyframes flickering-mixin {
        0% {
            opacity: 1;
        }

        5% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }
}
