$fs: 16;
$fs-mb: 16;
$padding-y-main-block-mobile: vw-vh-contain(40, true);
$margin-x-main-block-mobile: vw-vh-contain(24, true);

.outward {
    &--social-network {
        display: flex;
        gap: em(30, $fs);

        @media (--viewport-mobile-pt) {
            padding-right: calc(2 * $margin-x-main-block-mobile);
            padding-left: calc(2 * $margin-x-main-block-mobile);
            gap: em(20, $fs-mb);
        }

        .social-network {
            display: inline-block;
            width: em(30, $fs);
            height: em(30, $fs);
        }
    }
}
