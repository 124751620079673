.korean-heart {
    $fs: $base-px-desktop;
    $fs-mb: $base-px-mb;
    $w: 394.5;
    $h: 1105.97;
    $w-mb: 262.89;
    $h-mb: 736.99;

    font-size: vh($fs);
    position: absolute;
    right: vw(220);
    top: em(-266, $fs);
    width: em($w, $fs);
    height: em($h, $fs);
    z-index: 2;

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
        top: em(91, $fs-mb);
        left: 50%;
        transform: translateX(-50%);
        width: em($w-mb, $fs-mb);
        height: em($h-mb, $fs-mb);
    }

    &__anim,
    &__element {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__element {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__substrate {
        width: percent(363.8, $w);
        height: percent(1105.97, $h);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.67;

        .korean-heart__element {
            background-image: url("@/assets/images/icons/header/substrate.png");
        }
    }

    &__hand {
        $anim-timing-function: ease-out;

        width: percent(191.92, $w);
        height: percent(530.04, $h);
        position: absolute;
        top: percent(548, $h);
        left: percent(88, $w);
        transition-duration: 0.8s;
        transition-timing-function: $anim-timing-function;

        &:not(.entered) {
            transition-duration: 0.8s;
            transition-timing-function: $anim-timing-function;
            opacity: 0;
            transform: translate(percent(55, $w), percent(55, $h));
        }
    }

    &__waves {
        $anim-timing-function: ease-out;

        width: percent(297.3, $w);
        height: percent(244.47, $h);
        position: absolute;
        top: percent(357, $h);
        left: percent(52.8, $w);
        transition-duration: 0.9s;
        transition-delay: 0.4s;
        transition-timing-function: $anim-timing-function;

        &:not(.entered) {
            opacity: 0;
        }

        .korean-heart__element {
            $anim-duration: 15.9s;
            $anim-timing-function: ease-in;

            transform: scale(0.95);
            transform-origin: center center;
            animation: $anim-duration $anim-timing-function infinite alternate light-waves;
        }
    }

    &__luminance {
        $anim-timing-function: ease-out;

        width: percent(394.5, $w);
        height: percent(388.7, $h);
        position: absolute;
        top: percent(301, $h);
        left: percent(0.22, $w);
        transition-duration: 0.9s;
        transition-delay: 0.4s;
        transition-timing-function: $anim-timing-function;

        &:not(.entered) {
            transition-timing-function: $anim-timing-function;
            opacity: 0;
        }

        .luminance--light {
            $anim-duration: 15.9s;
            $anim-timing-function: ease-in-out;

            transform: scale(0.6);
            transform-origin: center center;
            animation: $anim-duration $anim-timing-function infinite alternate light-luminance;
        }

        .luminance--light-big {
            $anim-duration: 15.9s;
            $anim-timing-function: ease-in-out;

            transform: scale(0.8);
            transform-origin: center center;
            animation: $anim-duration $anim-timing-function 0.1s infinite alternate light-big-luminance;
        }
    }

    @keyframes light-luminance {
        0% {
            transform: scale(0.6);
        }

        40% {
            transform: scale(1);
        }

        41% {
            transform: scale(0.97);
        }

        42% {
            transform: scale(1);
        }

        61%,
        62% {
            transform: scale(1);
        }

        64% {
            transform: scale(0.97);
        }

        63% {
            transform: scale(1);
        }

        65% {
            transform: scale(0.97);
        }

        66%,
        75% {
            transform: scale(1);
        }

        76% {
            transform: scale(0.97);
        }

        78% {
            transform: scale(1);
        }

        79% {
            transform: scale(0.97);
        }

        80% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.6);
        }
    }

    @keyframes light-big-luminance {
        0% {
            transform: scale(0.8);
        }

        40% {
            transform: scale(1);
        }

        41% {
            transform: scale(0.99);
        }

        42% {
            transform: scale(1);
        }

        61%,
        62% {
            transform: scale(1);
        }

        64% {
            transform: scale(0.99);
        }

        63% {
            transform: scale(1);
        }

        65% {
            transform: scale(0.99);
        }

        66%,
        75% {
            transform: scale(1);
        }

        76% {
            transform: scale(0.99);
        }

        78% {
            transform: scale(1);
        }

        79% {
            transform: scale(0.99);
        }

        80% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.8);
        }
    }

    @keyframes light-waves {
        0% {
            transform: scale(0.95);
        }

        100% {
            transform: scale(1);
        }
    }
}
