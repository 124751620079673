.footer {
    $fs: $base-px-desktop;
    $fs-mb: 18;

    background: rgb(64 32 133 / 18%);

    @media (--viewport-mobile-pt) {
        font-size: vw-vh-contain($fs-mb, true);
        background: #39298a;
    }

    &__advertiser {
        color: rgb(24 255 255 / 43%);
        text-align: center;
        padding-top: em(22, $fs);
        line-height: 130%;

        @media (--viewport-mobile-pt) {
            font-size: em(13, $fs-mb);
            padding-top: em(22, 13);
        }
    }

    &__text {
        color: rgb(24 255 255 / 12%);
        text-align: center;
        padding-top: em(12, $fs);
        padding-bottom: em(15.5, $fs);
        line-height: 130%;

        @media (--viewport-mobile-pt) {
            padding-top: em(12, $fs-mb);
            padding-bottom: em(17.74, $fs-mb);
        }
    }
}
