@use "sass:math";

// colors
$color-bg: #3f2085;
$color-text: #fff;
$color-text-accent: #18ffff;

// text
$font-default: "Involve", system-ui, sans-serif;
$font-accent: "Caviar Dreams", system-ui, sans-serif;

// Basing Weights
$font-weight-default: 400;
$font-weight-accent: 500;
$font-weight-bold: 700;
$base-px-desktop: 18;
$base-width-desktop: 1440;
$base-height-desktop: 812;
$max-width-desktop: 1440;

// add
$base-px-mb: 16;
$base-width-mb: 375;
$base-height-mb: 812;

// Font sizes
// to calc 1rem with poly-fluid-sizing
$map-font-size-lp: (
    560px: 18px,
    850px: 18px,
    852px: 18px,
    1440px: 18px
);
$map-font-size-pt: (
    320px: 14px,
    375px: 16px,
    425px: 17px,
    1024px: 18px
);

// Add one more variable if mobile line-height differs from desktop one
$line-height-default: math.div(24, $base-px-desktop);

// Line height of accent font family text
$line-height-accent: math.div(126, 140);
$padding-x-content-desktop: em(140, $base-px-desktop);
$padding-x-content-mobile: em(35, $base-px-mb);
